import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import defaultTheme from './themes';
import Landing from './components/landing/Landing';
import GlobalStyle from './GlobalStyle';
import { FirebaseProvider } from './contexts/Firebase';
import { LoggingProvider } from './contexts/Logging';
import { PreferencesProvider } from './contexts/Preferences';
import fbaseProps from './constants/firebase';
import { BrowserRouter as Router } from 'react-router-dom';

const Canvas = styled.div`
  width: 100vw;
  height: 100vh;
  color: ${({ theme }) => theme.global.color};
  background-color: ${({ theme }) => theme.global.backgroundColor};
  overflow: auto;
`;

const App = () => (
  <Fragment>
    <GlobalStyle />

    <LoggingProvider>
      <PreferencesProvider>
        <FirebaseProvider {...fbaseProps}>
          <ThemeProvider theme={defaultTheme}>
            <Canvas>
              <Router>
                <Landing />
              </Router>
            </Canvas>
          </ThemeProvider>
        </FirebaseProvider>
      </PreferencesProvider>
    </LoggingProvider>
  </Fragment>
);

export default App;
