import React, { useContext } from 'react';
import SignIn from './SignIn';
import { FirebaseContext } from '../../contexts/Firebase';
import { useTransition, animated, config } from 'react-spring';

const AnimatedSignIn = () => {
  const { isAuthenticated } = useContext(FirebaseContext);

  const transitions = useTransition(isAuthenticated, null, {
    config: config.slow,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item, key, props }) => {
    return (
      !item && (
        <animated.div key={key} style={props}>
          <SignIn />
        </animated.div>
      )
    );
  });
};

export default AnimatedSignIn;
