export default {
  global: {
    color: '#9BA6A1',
    backgroundColor: '#141817',
    title: {
      fontFamily: 'Special Elite, cursive',
      color: '#E8F4EB',
    },
  },
};
