import React from 'react';
import MenuItem from './MenuItem';
import { prv } from '../../../constants/routes';
import icon from '../../../assets/img/profile.svg';
import iconActive from '../../../assets/img/profile-active.svg';
import iconHover from '../../../assets/img/profile-hover.svg';

const MenuProfile = () => (
  <MenuItem
    label="Profile"
    to={prv.PROFILE}
    icon={icon}
    iconActive={iconActive}
    iconHover={iconHover}
  />
);

export default MenuProfile;
