import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Home = () => {
  return <Wrapper>Home</Wrapper>;
};

export default Home;
