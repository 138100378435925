export default {
  apiKey:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_API_KEY) ||
    'AIzaSyDaZkgnwepF6i3C0GnXb9Zp0yoDuwv57lI',
  authDomain:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_AUTH_DOMAIN) ||
    'tacorat-ce4a.firebaseapp.com',
  databaseURL:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_DB_URL) ||
    'https://tacorat-ce4a.firebaseio.com',
  projectId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_PROJECT_ID) ||
    'tacorat-ce4a',
  storageBucket:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_BUCKET) ||
    'tacorat-ce4a.appspot.com',
  messagingSenderId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_MSG_SENDER_ID) ||
    '80042741345',
};
