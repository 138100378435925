import React, { useContext, useRef } from 'react';
import { STATUS_HEIGHT } from '../../constants/dimensions';
import styled from 'styled-components';
import { FirebaseContext } from '../../contexts/Firebase';
import { useChain, useTransition, animated, config } from 'react-spring';

const Wrapper = styled.div`
  position: relative;
  bottom: 0px;
  height: ${STATUS_HEIGHT}px;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  z-index: 1000;
  box-shadow: 0 0px 15px 0 rgba(20, 0, 0, 0.4);
`;
const AnimatedWrapper = animated(Wrapper);

const StatusLine = () => {
  const { isAuthenticated } = useContext(FirebaseContext);

  const ref = useRef();
  const transitions = useTransition(isAuthenticated, null, {
    config: config.slow,
    ref,
    from: { transform: `translate3d(0,${STATUS_HEIGHT}px,0)`, opacity: 0 },
    enter: { transform: `translate3d(0,0px,0)`, opacity: 1 },
    leave: { transform: `translate3d(0,${STATUS_HEIGHT}px,0)`, opacity: 0 },
  });

  // use a chain to delay the start of the animation
  useChain([ref], [0]);

  return transitions.map(({ item, key, props }) => {
    return (
      item && (
        <AnimatedWrapper key={key} style={props}>
          Status
        </AnimatedWrapper>
      )
    );
  });
};

export default StatusLine;
