export const pub = {
  LANDING: '/',
  LOGIN: '/login',
  NO_ACCESS: '/noAccess',
};

export const prv = {
  PROFILE: `/profile`,
  WARROOM: `/warroom`,
  NEW_USER: `/newUser`,
  HOME: `/home`,
};
