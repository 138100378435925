import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledNavLink = styled(NavLink)`
  color: cyan;
  text-decoration: none;

  &:visited {
    color: cyan;
  }

  & > img.default {
  }

  & > img.active {
    display: none;
  }

  & > img.hover {
    display: none;
  }

  &:hover {
    & > img.default {
      display: none !important;
    }

    & > img.active {
      display: none !important;
    }

    & > img.hover {
      display: block;
    }
  }

  &.active {
    color: white !important;
    font-weight: bold;
    text-transform: uppercase;

    & > img.default {
      display: none;
    }

    & > img.active {
      display: block;
    }
  }
`;

const MenuItem = ({ to, label, icon, iconActive, iconHover }) => {
  return (
    <StyledNavLink to={to}>
      <img className="default" src={icon} alt={label} />
      <img className="active" src={iconActive} alt={label} />
      <img className="hover" src={iconHover} alt={label} />
    </StyledNavLink>
  );
};

export default MenuItem;
