import styled from 'styled-components';

const Loading = styled.div`
  text-align: center;
  background-color: ${props => props.theme.backgroundColor};

  &:after {
    content: 'Loading...';
  }
`;

export default Loading;
