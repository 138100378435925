import React from 'react';
import styled from 'styled-components/macro';
import Routes from './Routes';
import { STATUS_HEIGHT } from '../../constants/dimensions';
import Menu from './menu/Menu';
import StatusLine from './StatusLine';

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const RouteLayout = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 100px;
  overflow: auto;
`;

const StatusLayout = styled.div`
  position: relative;
  flex: 0 0 ${STATUS_HEIGHT}px;
  height: ${STATUS_HEIGHT}px;
  overflow: hidden;
`;

const Authenticated = () => {
  return (
    <Layout>
      <RouteLayout>
        <Menu />
        <Routes />
      </RouteLayout>

      <StatusLayout>
        <StatusLine />
      </StatusLayout>
    </Layout>
  );
};

export default Authenticated;
