import React, { useContext } from 'react';
import { FirebaseContext } from '../../contexts/Firebase';
import { withRouter } from 'react-router-dom';

const SignOut = ({ history }) => {
  const { firebase } = useContext(FirebaseContext);

  const signOut = async () => {
    await firebase.auth().signOut();

    history.push('/');
  };

  return (
    <div>
      <button onClick={signOut}>Sign Out</button>
    </div>
  );
};

export default withRouter(SignOut);
