import React from 'react';
import styled from 'styled-components/macro';
import { Switch, Route, Redirect } from 'react-router-dom';
import { prv } from '../../constants/routes';
import asyncComponent from '../shared/AsyncComponent';
import Home from './Home';
import Profile from '../profile/Profile';
import NewUser from '../profile/NewUser';
import NotFound from './NotFound';
import { animated, useTransition } from 'react-spring';
import useRouter from './useRouter';
import { MENU_WIDTH } from '../../constants/dimensions';

/**
 * Lazy Load the following components...
 */
const Warroom = asyncComponent(async () => {
  const module = await import('../warroom/Warroom');
  return module.default;
});

const Wrapper = styled.div`
  position: absolute;
  left: ${MENU_WIDTH}px;
  flex: 1 1 100px;
  width: calc(100% - ${MENU_WIDTH}px);
  height: 100%;
  overflow: auto;
`;

const AnimatedWrapper = animated(Wrapper);

const MainCanvas = () => {
  const { location } = useRouter();
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    // from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    // enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    // leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  });
  return transitions.map(({ item, props, key }) => (
    <AnimatedWrapper key={key} style={props}>
      <Switch location={item}>
        <Route exact path={prv.HOME} component={Home} />
        <Route path={prv.PROFILE} component={Profile} />
        <Route path={prv.WARROOM} component={Warroom} />
        <Route path={prv.NEW_USER} component={NewUser} />
        <Redirect to={prv.HOME} />
        <Route component={NotFound} />
      </Switch>
    </AnimatedWrapper>
  ));
};

export default MainCanvas;
