import React, { useEffect, useContext } from 'react';
import imgLandingBg from '../../assets/img/landing-bg.png';
import styled from 'styled-components/macro';
import { FirebaseContext } from '../../contexts/Firebase';
import { useSpring, animated, config } from 'react-spring';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Canvas = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${imgLandingBg});
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(0px) brightness(100%);
`;
const AnimatedCanvas = animated(Canvas);

const Background = () => {
  const { isAuthenticated, initialized } = useContext(FirebaseContext);

  const [propsBg, setBg] = useSpring(() => ({
    config: config.slow,
    transform: 'translate(0px,0px) scale(1)',
    filter: `blur(0px) brightness(100%)`,
  }));

  useEffect(
    () => {
      if (!initialized) return;

      if (isAuthenticated) {
        setBg({
          transform: 'translate(-40px, 20px) scale(1.08)',
          filter: `blur(8px) brightness(62%)`,
          // delay: 310,
        });
      } else {
        setBg({
          transform: 'translate(0px,0px) scale(1)',
          filter: `blur(0px) brightness(100%)`,
        });
      }
    },
    [initialized, isAuthenticated, setBg],
  );

  return (
    <Wrapper>
      <AnimatedCanvas style={propsBg} />
    </Wrapper>
  );
};

export default Background;
