import React from 'react';
import MenuItem from './MenuItem';
import { prv } from '../../../constants/routes';
import iconHome from '../../../assets/img/home.svg';
import iconHomeActive from '../../../assets/img/home-active.svg';
import iconHomeHover from '../../../assets/img/home-hover.svg';

const MenuHome = () => (
  <MenuItem
    label="Home"
    to={prv.HOME}
    icon={iconHome}
    iconActive={iconHomeActive}
    iconHover={iconHomeHover}
  />
);

export default MenuHome;
