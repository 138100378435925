import React, { useState } from 'react';

export const PreferencesContext = React.createContext({
  blur: false,
  setBlur: () => console.log('blurrr...'),
});

export const PreferencesProvider = ({ children }) => {
  const [blur, setBlur] = useState(false);

  return (
    <PreferencesContext.Provider
      value={{
        blur,
        setBlur,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};
