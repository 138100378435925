import React, { useEffect, useContext } from 'react';
import { FirebaseContext } from '../../contexts/Firebase';
import styled from 'styled-components/macro';
import { useSpring, animated } from 'react-spring';

const Wrapper = styled.h1`
  position: absolute;
  top: 0;
  right: 40px;
  transform: rotate(5deg);
  text-align: right;
  width: fit-content;
  color: ${({ theme }) => theme.global.title.color};
  font-family: ${({ theme }) => theme.global.title.fontFamily};
  font-size: 2.25em;
  line-height: 3.375em;
  letter-spacing: 0.1em;
`;
const AnimatedWrapper = animated(Wrapper);

const SiteTitle = ({ children }) => {
  const { isAuthenticated, initialized } = useContext(FirebaseContext);

  const [propsTitle, setTitle] = useSpring(() => ({
    // config: config.slow,
    transform: 'translate(0px,0px) rotate(5deg)',
    top: 0,
    right: 40,
    fontSize: '2.25em',
    opacity: 1,
  }));

  useEffect(
    () => {
      if (!initialized) return;

      if (isAuthenticated) {
        setTitle({
          transform: 'translate(10px,0px) rotate(-1deg)',
          fontSize: '1em',
          lineHeight: '1em',
          opacity: 0.75,
        });
      } else {
        setTitle({
          transform: 'translate(0px,5px) rotate(5deg)',
          fontSize: '2.25em',
          lineHeight: '3.375em',
          opacity: 1,
        });
      }
    },
    [initialized, isAuthenticated, setTitle],
  );

  return <AnimatedWrapper style={propsTitle}>{children}</AnimatedWrapper>;
};
export default SiteTitle;
