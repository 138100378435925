import React, { useContext, useRef } from 'react';
import SignOut from '../SignOut';
import styled from 'styled-components/macro';
import Color from 'color2';
import { FirebaseContext } from '../../../contexts/Firebase';
import { MENU_WIDTH } from '../../../constants/dimensions';
import MenuHome from './MenuHome';
import MenuWarroom from './MenuWarroom';
import MenuProfile from './MenuProfile';
import { useChain, useTransition, animated } from 'react-spring';

const Layout = styled.div`
  position: relative;
  width: ${MENU_WIDTH}px;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: -${MENU_WIDTH}px;
  width: ${MENU_WIDTH}px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props =>
    new Color(props.theme.global.backgroundColor).darken(0.5).alpha(0.7)};
`;
const AnimatedWrapper = animated(Wrapper);

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const Menu = () => {
  const { isAuthenticated } = useContext(FirebaseContext);

  const menuRef = useRef();
  const menuTransitions = useTransition(isAuthenticated, null, {
    ref: menuRef,
    from: { transform: `translate3d(0px,0,0)`, opacity: 0 },
    enter: { transform: `translate3d(${MENU_WIDTH}px,0,0)`, opacity: 1 },
    leave: { transform: `translate3d(0px,0,0)`, opacity: 0 },
  });

  // use a chain to delay the start of the animation
  useChain([menuRef], [0.23]);

  return menuTransitions.map(({ item, key, props }) => {
    return (
      item && (
        <Layout>
          <AnimatedWrapper key={key} style={props}>
            <Group>
              <MenuHome />
              <MenuWarroom />
            </Group>
            <Group>
              <MenuProfile />
              <SignOut />
            </Group>
          </AnimatedWrapper>
        </Layout>
      )
    );
  });
};

export default Menu;
