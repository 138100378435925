import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../contexts/Firebase';

const TextInput = ({ label, defaultValue, onSuccess }) => {
  const [cached, setCached] = useState();
  const [value, setValue] = useState();
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    setCached(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <label>{label}</label>
      <input
        type="text"
        defaultValue={value}
        onFocus={() => setShowButtons(true)}
        onBlur={() => {
          if (cached === value) {
            setShowButtons(false);
          }
        }}
        onChange={e => {
          console.log(e.target.value, 'cached:', cached, 'value:', value);
          if (!cached) {
            console.log('caching', value);
            setCached(value);
          }
          setValue(e.target.value);
        }}
      />
      {showButtons && (
        <span>
          <button
            onClick={() => {
              setValue(cached);

              setShowButtons(false);
            }}
          >
            Cancel
          </button>
          <button disabled={cached === value} onClick={() => onSuccess(value)}>
            Ok
          </button>
        </span>
      )}
    </div>
  );
};

TextInput.defaultProps = {
  onSuccess: () => console.log('click ok'),
  onCancel: () => console.log('click cancel'),
};

const NewUser = () => {
  const { user } = useContext(FirebaseContext);
  const [displayName, setDisplayName] = useState(null);

  useEffect(() => {
    setDisplayName(user.displayName);
  }, [user.displayName]);

  return (
    <div>
      <div>New User</div>
      <TextInput
        label="Display Name"
        defaultValue={displayName}
        onSuccess={async value => {
          console.log('PAYLOAD', { displayName: value });
          await user.updateProfile({ displayName: value });
          console.log('DONE');
        }}
      />
    </div>
  );
};

export default NewUser;
