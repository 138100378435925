import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { FirebaseContext } from '../../contexts/Firebase';
import Authenticated from '../authenticated/Authenticated';
import UnAuthenticated from './UnAuthenticated';
import Loading from './Loading';
import Background from './Background';
import SiteTitle from './SiteTitle';
import { useTransition, animated, config } from 'react-spring';

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainLayout = styled.div`
  flex: 1 1 100px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const Landing = () => {
  const { isAuthenticated, initialized } = useContext(FirebaseContext);
  const [comps, set] = useState([<UnAuthenticated />]);

  useEffect(
    () => {
      if (isAuthenticated) {
        set([<Authenticated />]);
      } else {
        set([<UnAuthenticated />]);
      }
    },
    [isAuthenticated],
  );

  const transitions = useTransition(comps, null, {
    config: config.slow,
    from: { opacity: 1, position: 'relative', width: '100vw', height: '100vh' },
    enter: {
      opacity: 1,
      position: 'relative',
      width: '100vw',
      height: '100vh',
    },
    leave: {
      opacity: 0,
      position: 'relative',
      width: '100vw',
      height: '100vh',
    },
  });

  return (
    <Wrapper>
      <Background />

      <MainLayout>
        <SiteTitle>Taco Rat Club.</SiteTitle>

        {!initialized && <Loading />}

        {transitions.map(({ item, key, props }) => {
          return (
            <animated.div key={key} style={props}>
              {item}
            </animated.div>
          );
        })}
      </MainLayout>
    </Wrapper>
  );
};

export default Landing;
