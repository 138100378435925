import React from 'react';
import SignIn from './AnimatedSignIn';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const UnAuthenticated = () => {
  return (
    <Wrapper>
      <SignIn />
    </Wrapper>
  );
};

export default UnAuthenticated;
