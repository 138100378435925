import React from 'react';
import MenuItem from './MenuItem';
import { prv } from '../../../constants/routes';
import iconWarroom from '../../../assets/img/warroom.svg';
import iconWarroomActive from '../../../assets/img/warroom-active.svg';
import iconWarroomHover from '../../../assets/img/warroom-hover.svg';

const MenuWarroom = () => (
  <MenuItem
    label="Warroom"
    to={prv.WARROOM}
    icon={iconWarroom}
    iconActive={iconWarroomActive}
    iconHover={iconWarroomHover}
  />
);

export default MenuWarroom;
