import React, { useState } from 'react';

export const LoggingContext = React.createContext({
  logs: [],
});

export const LoggingProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);
  const debug = message => {
    console.debug(message);
    setLogs(logs.concat({ type: 'debug', message }));
  };

  const info = message => {
    console.log(message);
    setLogs(logs.concat({ type: 'info', message }));
  };

  const warn = message => {
    console.warn(message);
    setLogs(logs.concat({ type: 'warn', message }));
  };

  const error = message => {
    console.error(message);
    setLogs(logs.concat({ type: 'error', message }));
  };

  return (
    <LoggingContext.Provider
      value={{
        debug,
        info,
        error,
        warn,
        logs,
      }}
    >
      {children}
    </LoggingContext.Provider>
  );
};
