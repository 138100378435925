import { useState, useEffect } from 'react';

const useLocalStorage = key => {
  const [state, setState] = useState(window.localStorage.getItem(key));

  // when the state changes, write the value to local storage
  useEffect(() => {
    if (!state) {
      return;
    }
    window.localStorage.setItem(key, state);
  }, [key, state]);

  const delKey = () => {
    window.localStorage.removeItem(key);
    setState('');
  };

  return [state, setState, delKey];
};

export default useLocalStorage;
