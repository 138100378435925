import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import React, { useEffect, useState } from 'react';

export const FirebaseContext = React.createContext({
  firebase: {},
  database: null,
  storage: null,
  user: null,
  initialized: false,
});

export const FirebaseProvider = ({
  children,
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState(null);
  const [profile] = useState(null);
  const [fbase, setFbase] = useState({});
  const [initialized, setInitialized] = useState(false);

  // const initUser = () => {
  //   console.log('USER', user.uid);
  //   fbase.database.doc(`/users/${user.id}`);
  // };

  useEffect(() => {
    fb.initializeApp({
      apiKey,
      authDomain,
      databaseURL,
      projectId,
      storageBucket,
      messagingSenderId,
    });

    const database = fb.firestore();
    const storage = fb.storage().ref();

    setFbase({ database, storage, firebase: fb });

    setTimeout(() => {
      fb.auth().onAuthStateChanged(user => {
        console.debug('Auth State Changed:', user);
        if (user) {
          setUser(user);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setInitialized(true);
      });
    }, 1000);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (!user) {
  //     return;
  //   }

  //   const getProfile = async user => {
  //     const doc = await fbase.database
  //       .collection('users')
  //       .doc(user.uid)
  //       .get();

  //     console.log('DOC', doc.exists);
  //   };

  //   getProfile(user);

  //   //   if (!doc.exists) {

  //   //   }
  //   // doc.onSnapshot(doc => {
  //   //   setProfile(doc.data());
  //   // });

  //   // return () => {
  //   //   if (unsub && typeof unsub === 'function') {
  //   //     unsub();
  //   //   }
  //   // };
  // }, [fbase.database, user]);

  return (
    <FirebaseContext.Provider
      value={{
        ...fbase,
        profile,
        isAuthenticated,
        user,
        initialized,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
