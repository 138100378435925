import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../contexts/Firebase';
import { LoggingContext } from '../../contexts/Logging';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import useLocalStorage from '../../hooks/useLocalStorage';

const Wrapper = styled.div`
  position: absolute;
  width: 400px;
  bottom: 40px;
  right: 40px;
  color: white;
`;

const SignIn = ({ history }) => {
  const { firebase, database } = useContext(FirebaseContext);
  const { info, error } = useContext(LoggingContext);

  const [email, setEmail] = useState('abuecker@gmail.com');
  const [signInEmail, setSignInEmail] = useLocalStorage('signInEmail');
  const [doingSomething, setDoingSomething] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);

  const [, setShowSameBrowser] = useState(false);

  useEffect(
    () => {
      const processLink = async () => {
        if (!firebase) {
          return;
        }
        console.log('process link');

        // check that this is a sign in link
        if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
          info(`"${window.location.href}" is not a sign in link.  Skipping.`);
          return;
        }

        console.debug('SIGN IN LINK');
        console.debug('SIGN IN EMAIL', signInEmail);

        if (!signInEmail) {
          // TODO User opened the link on a different device. To prevent session fixation attacks, ask the
          // TODO user to provide the associated email again.
          setShowSameBrowser(true);

          return;
        }

        console.debug(
          'SIGNING IN TO FIREBASE:',
          signInEmail,
          window.location.href,
        );

        let result;
        try {
          result = await firebase
            .auth()
            .signInWithEmailLink(signInEmail, window.location.href);
        } catch (e) {
          console.debug('UNABLE TO SIGN IN TO FIREBASE', e);
          switch (e) {
            case 'auth/invalid-action-code':
              error(
                `The email provided (${signInEmail}) does not match the email used to start the sign in process.`,
              );
              return;

            default:
              error('Something went wrong signing in:', e);
              return;
          }
        }

        console.debug('SIGNED IN TO FIREBASE:', result);
        const { user, additionalUserInfo } = result;
        console.log('USER', user, additionalUserInfo);

        // delete sign in email from localstorage
        window.localStorage.removeItem('signInEmail');

        // New User
        if (additionalUserInfo.isNewUser) {
          console.log('NEW USER');
          await database.doc(`/users/${user.id}`).set({ newUser: true });
          return history.push('/p/newUser');
        }

        // go to `home`
        history.push('/');
      };

      processLink();
    },
    [firebase, database, error, history, info, signInEmail],
  );

  const onProceed = async () => {
    console.log('EMAIL', email);
    setDoingSomething(true);

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: window.location.href,
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.example.ios',
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12',
      // },
      dynamicLinkDomain:
        (process.env.NODE_ENV === 'development' &&
          process.env.REACT_APP_DYNAMIC_LINK) ||
        'tacoratclub.page.link',
    };

    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
    setCheckEmail(true);
    setSignInEmail(email);
  };

  if (!checkEmail && doingSomething) {
    return <Wrapper>Doing Something...</Wrapper>;
  }

  if (!checkEmail && !doingSomething) {
    return (
      <Wrapper>
        <label>Email:</label>
        <input
          id="email"
          type="email"
          defaultValue={email}
          onChange={e => setEmail(e.target.value)}
        />

        <button type="button" onClick={onProceed}>
          Go
        </button>

        <div>Simply input your email, wether you have an account or not.</div>
        <div>
          We'll either <b>log you in</b> or <b>sign you up</b>.
        </div>
      </Wrapper>
    );
  }

  if (checkEmail) {
    return <Wrapper>Check your email for the next step.</Wrapper>;
  }
};

export default withRouter(SignIn);
